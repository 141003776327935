body {
  background-color: rgb(33, 37, 41) !important;
}

footer {
  width: 100%;
  bottom: 0;
  z-index: 99999999999999;
  margin-top: 100px;
}

main {
  margin-top: 90px;
}

h1 {
  font-weight: 300;
}

.h4 {
  margin-bottom: 0;
}

.navbar {
  -webkit-box-shadow: 0 8px 6px -6px #1a1a1a;
  -moz-box-shadow: 0 8px 6px -6px #1a1a1a;
  box-shadow: 0 8px 6px -6px #1a1a1a;
}

.accordion-header,
.accordion-header > button,
.accordion-item {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.accordion-header > button[aria-expanded="false"] {
  color: white;
  font-weight: bold;
  background-image: url("./assets/bg-orange.jpg");
  background-repeat: no-repeat;
  background-position: center;
}

.accordion-header > button[aria-expanded="true"] {
  color: white;
  font-weight: bold;
  background-image: url("./assets/bg-orange.jpg");
  background-repeat: no-repeat;
  background-position: center;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.accordion-header > button:focus,
button.btn-primary:focus {
  border-color: rgb(255, 121, 0) !important;
  box-shadow: 0 0 0 0.25rem rgb(255 121 0 / 25%) !important;
}

.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

button.btn-primary {
  border-color: rgb(255, 121, 0) !important;
  background-color: rgb(255, 121, 0) !important;
}

button.btn-primary:focus,
button.btn-primary:hover {
  border-color: rgb(255, 121, 0) !important;
  background-color: rgb(255, 90, 0) !important;
}

#userStatus {
  background-color: #1a1a1a;
  border-radius: 15px;
  text-transform: uppercase;
  font-size: 0.8em;
  line-height: 1.3em;
}

#userStatus .number {
  font-weight: bold;
  font-size: 20px;
}

.accordion-header button {
  text-transform: uppercase;
  font-size: 0.4em;
  line-height: 0.5em;
}

.accordion-header p {
  margin-bottom: 10px;
}
